import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Breadcrumb, Carousel, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { isIterableArray } from '../block-slider/utils';
import RenderBlocks from '../builderPage/blocks/RenderBlocks';
import { setMetaTags } from '../builderPage/BuilderPage';
import SideMenu from '../builderPage/SideMenu';
import SkeletonMenu from '../builderPage/SkeletonMenu';
import PopUpController from '../popUp/PopUpController';

const ContactsPage = () => {

    const [cardsData, setCardsData] = useState(null);
    const [menuData, setMenuData] = useState(null);

    const [data, setData] = useState(null);

    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        fetchData()
    }, []);


    const fetchData = () => {
        setIsLoading(true)
        axios.get(`${process.env.REACT_APP_API_BASE}/pages/page/contact`)
            .then((response) => {
                // console.log('contact list data response', response)

                if (response.data.meta_tags) setMetaTags(response.data.meta_tags)

                setData(response.data)
                setCardsData(response.data.centers)

                setMenuData(response.data.sidebar)

                // if (isIterableArray(response?.data?.sidebar?.items)) {
                //     const crumbs = createCrumbs(response.data.sidebar.items)
                //     setCrumbs(crumbs)
                // }
                // document.title = response.data.name
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }


    return (
        <main>
            {
                data?.id &&
                <PopUpController
                    sendBody={{
                        id: data?.id,
                        type: 3,
                        slug: data?.slug
                    }}
                    pageSlug={'page-contacts'}
                />
            }
            <Container>
                <Breadcrumb>
                    <Breadcrumb.Item href="/"><i className="i-home"></i></Breadcrumb.Item>
                    <Breadcrumb.Item href="/info">
                        {'Информация'}
                    </Breadcrumb.Item>
                    {
                        data?.name &&
                        <li className='breadcrumb-item'>
                            {data.name}
                        </li>
                    }
                </Breadcrumb>
                <div className='page-contacts-cards buildedPage-container'>

                    {
                        menuData ?
                            <SideMenu menuData={menuData} />
                            :
                            <SkeletonMenu />
                    }

                    {
                        data &&
                        <div className='buildedPage-content'>

                            <h1 className="page-title">
                                {data.name}
                            </h1>
                            {
                                isIterableArray(cardsData) &&
                                <div className='row'>
                                    {
                                        cardsData.map((item, key) => (
                                            <div className='col-sm-6' key={key}>
                                                <div className='contacts__card'>
                                                    <Link to={`/contact/${item.slug}`} className='link-name'>
                                                        <span className='name'> {item.card_name}</span>
                                                        <span className='more' style={{ color: item.colorLink }}>
                                                            {item.textLink}
                                                            <span className='arrow' style={{ borderLeftColor: item.colorLink }}></span>
                                                        </span>
                                                    </Link>
                                                    <div className='contacts__card-content'>
                                                        <div className='address'>
                                                            {item.address}
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-8 col-lg-12 col-xl-8'>
                                                                <div dangerouslySetInnerHTML={{ __html: item.card_info }} />
                                                            </div>
                                                            <div className='col-4 col-lg-12 col-xl-4'>
                                                                <div dangerouslySetInnerHTML={{ __html: item.worktime }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        isIterableArray(item?.content?.blocks?.images) &&
                                                        <Carousel
                                                            className='contacts__card-slider'
                                                            nextIcon={<span className="arrow-icon next" />}
                                                            prevIcon={<span className="arrow-icon prev" />}
                                                            interval={null}
                                                            indicators={false}
                                                        >
                                                            {
                                                                item.content.blocks.images.map((imgItem, index) => {
                                                                    return (
                                                                        <Carousel.Item key={index}>
                                                                            <img src={process.env.REACT_APP_BACKEND + 'uploads/pages/blocks/' + imgItem.src} alt={imgItem.name} />
                                                                        </Carousel.Item>
                                                                    )
                                                                })
                                                            }
                                                        </Carousel >
                                                    }
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            }

                            {
                                data?.content &&
                                <RenderBlocks data={data.content} />
                            }
                        </div>

                    }
                </div>
            </Container>
        </main>
    )
}

export default ContactsPage
